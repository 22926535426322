import { DEFAULT_AD_PROFILE } from 'data/config/profileChooseConfig';
import { CONFIG_JSON_LOADED, LANG_CHANGED, PROFILE_CHANGED } from 'src/store/actionTypes';

import { get as getLabels } from 'src/core/Lang';
import { get as getProfile } from 'src/core/Profile';
import { getAdConfig } from 'src/core/config-json/ConfigJsonManager';

import { updateObject, configJsonLoaded, langChanged, profileChanged } from 'src/store/reducers/commons';

function getDefaultState() {
  return {
    profile: getProfile(),
    labels: getLabels(),
  };
}

export default (state = getDefaultState(), action) => {
  switch (action.type) {

    case CONFIG_JSON_LOADED:
      return updateObject(state, {
        adConfig: getAdConfig(state.profile || DEFAULT_AD_PROFILE),
      });

    case LANG_CHANGED:
      return langChanged(state, action);

    case PROFILE_CHANGED:
      return profileChanged(state, action, null, true);

    default:
      return state;
  }
};
