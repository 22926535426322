import React, { useState } from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

import DummyKeyboardPlaceholder from 'src/components/dummy-keyboard-placeholder/DummyKeyboardPlaceholder';
import showConfirmModal from 'src/core/util/showConfirmModal';

import {
  checkLicence,
  registerCheckpoint,
  parseStringifiedDateFromKLeadsBackend,
  TEST_DATA,
} from 'src/core/webservices/klipso-leads/KlipsoLeadsWS';

import { getMobileId, getMobileName } from 'src/core/cordova/CordovaHelper';
import { scan } from 'src/core/cordova/BarCodeScanner';
import Push from 'src/core/push';
import showGenericWsErrorNotification from 'src/core/webservices/showGenericWsErrorNotification';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { removeSpecialCharacters } from 'src/core/util/StringUtil';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';
import TermsOfUse from '../terms-of-use/TermsOfUse';
import OverlayLoader from '../overlay-loader/OverlayLoader';
import FooterGenericButton from '../footer/FooterGenericButton';
import Footer from '../footer/Footer';
import Content from '../content/Content';
import Header from '../header/Header';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './LicenceScreen.scss';

global.klMockFormFields = false;

function LicenceScreen({ userName, labels, actions, keyboardHeight }) {
  let mobileId;
  let mobileName;
  //Extraire le mot 'allowed' de la chaine
  let warningSeparator = labels.klipsoLeads.alphanumericCharWarning.split(' ')[3];
  if (!global.isCordovaContext && config.ENV === 'dev') {
    // DEV
    mobileId = TEST_DATA.mobileId;
    mobileName = TEST_DATA.mobileName;
  } else {
    mobileId = getMobileId();
    mobileName = getMobileName();
  }

  const [_licence, setLicence] = useState('');
  const [_userName, setUserName] = useState(userName || '');
  const [requestOngoing, setRequestOngoing] = useState(false);
  const [validCharacters, setValidCharacters] = useState(true);

  function onChangeLicence(event) {
    setLicence(event.target.value);
  }

  function onChangeUserName(event) {
    setValidCharacters(true);
    // The string without invalid characters
    const normalizedString = removeSpecialCharacters(event.target.value);
    // Set _userName to the  string  with invalid  characters
    setUserName(event.target.value);
    // Check if there is invalid  characters
    if (normalizedString !== event.target.value) {
      setValidCharacters(false);
    } else {
      setValidCharacters(true);
    }
  }

  async function scanLicence() {
    try {
      const result = await scan(config.KLIPSO_LEADS.LICENCE_SCAN_ACCEPTED_FORMATS);

      // Test: Empty scan result
      if (!result || typeof result.text !== 'string' || result.text.length === 0) {
        setLicence('');
      } else {
        const scannedLicence = result.text;

        // Test: Crypted badge
        if (
          scannedLicence[0] === '#' ||
          config.KLIPSO_LEADS.LICENCE_REGEXP.test(scannedLicence) !== true
        ) {
          actions.showNotification({
            message: labels.klipsoLeads.licenceScanAttemptOfCryptedBadge,
            level: NotificationLevels.INFO,
          });
          setLicence('');
        }
        // Scan ok
        else {
          setLicence(scannedLicence);
        }
      }
    } catch (e) {
      actions.showNotification({
        message: labels.klipsoLeads.scanDenied,
        level: NotificationLevels.WARNING,
      });
    }
  }

  function detectEnter(e) {
    if (e.key === 'Enter') {
      submit();
    }
  }

  async function submit() {
    if (validCharacters === true) {
      if (requestOngoing) {
        return;
      }

      if (!_licence && !_userName) {
        actions.showNotification({
          message: labels.klipsoLeads.lsEmptyLicenceAndUsername,
        });
        return;
      }
      if (!_licence) {
        actions.showNotification({
          message: labels.klipsoLeads.lsEmptyLicence,
        });
        return;
      }
      if (!_userName) {
        actions.showNotification({
          message: labels.klipsoLeads.lsEmptyUsername,
        });
        return;
      }

      setRequestOngoing(true);

      try {
        const licenceSuccessResponse = await checkLicence(_licence, mobileId);

        const registerSuccessResponse = await registerCheckpoint(
          licenceSuccessResponse.InstanceName,
          licenceSuccessResponse.ExhibitorId,
          _userName,
          mobileId,
          mobileName
        );

        setRequestOngoing(false);
        actions.klipsoLeadsRegisterSuccess({
          licence: _licence,
          userName: _userName,
          instance: licenceSuccessResponse.InstanceName,
          exhibitorId: licenceSuccessResponse.ExhibitorId,
          exhibitorName: licenceSuccessResponse.ExhibitorName,
          eventDateBegin: licenceSuccessResponse.EventDateBegin,
          eventDateEnd: licenceSuccessResponse.EventDateEnd,
          eventDateEndAsLong: parseStringifiedDateFromKLeadsBackend(
            licenceSuccessResponse.EventDateEnd
          ),
          eventId: licenceSuccessResponse.EventId,
          eventName: licenceSuccessResponse.EventName,
          checkPointId: registerSuccessResponse.CheckPointId,
          formFields: global.klMockFormFields
            ? TEST_DATA.FormFields
            : registerSuccessResponse.FormFields,
        });

        Push.setTag('KlipsoLeads', _licence);
      } catch (e) {
        if (e === WS_ERRORS.MAX_LICENCE) {
          actions.showNotification({
            message: labels.klipsoLeads.maxLicenceQuotaReached,
          });
        } else if (e === WS_ERRORS.NO_AUTHORIZATION) {
          actions.showNotification({
            message: labels.klipsoLeads.noAuthorization,
          });
        } else {
          console.error('Failed to check licence and register', e);
          showGenericWsErrorNotification(e);
        }
        setRequestOngoing(false);
      }
    } else {
      actions.showNotification({
        message: labels.klipsoLeads.alphanumericCharWarning,
        level: NotificationLevels.ERROR,
      });
    }
  }

  const isKeyboardVisible = typeof keyboardHeight === 'number' && keyboardHeight > 0;

  return (
    <div id="kl-ls" className={isKeyboardVisible ? 'kl-ls-keyboard-visible' : ''}>
      <Header labels={labels} actions={actions} onCloseClick={() => actions.navigateToHome()} />

      <Content>
        {requestOngoing && <OverlayLoader position="bottom" />}

        <form onSubmit={submit}>
          <div className="kl-ls-row">
            <div>{labels.klipsoLeads.licenceScreenFields.userName}</div>

            <div className={validCharacters ? 'kl-ls-field' : 'kl-ls-field-warning'}>
              <input
                type="text"
                value={_userName}
                onChange={onChangeUserName}
                onKeyUp={detectEnter}
                placeholder={labels.klipsoLeads.placeholders.userName}
              />
            </div>

            {validCharacters ? (
              <div />
            ) : (
              <strong className="kl-ls-warning">
                {' '}
                {labels.klipsoLeads.alphanumericCharWarning.split(warningSeparator)[0]}
                <br></br>
                {warningSeparator}
                {labels.klipsoLeads.alphanumericCharWarning.split(warningSeparator)[1]}
              </strong>
            )}
          </div>
          <div></div>
          <div className="kl-ls-row">
            <div>{labels.klipsoLeads.licenceScreenFields.licence}</div>
            <div className="kl-ls-field">
              <GenericIcon className="kl-ls-scan-btn qr-code-icon" onClick={scanLicence} />
              <input
                type="text"
                value={_licence}
                onChange={onChangeLicence}
                onKeyUp={detectEnter}
              />
            </div>
          </div>
        </form>

        <TermsOfUse labels={labels} />
      </Content>

      <Footer>
        <FooterGenericButton isTextButton callback={submit}>
          {labels.klipsoLeads.validate}
        </FooterGenericButton>
      </Footer>

      <DummyKeyboardPlaceholder keyboardHeight={keyboardHeight} />
    </div>
  );
}

LicenceScreen.propTypes = {
  userName: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  keyboardHeight: PropTypes.number,
};

export default LicenceScreen;
