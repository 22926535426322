import React from 'react';
import PropTypes from 'prop-types';

import { saveVisitorRelationList } from 'src/core/webservices/klipso-leads/KlipsoLeadsWS';
import showGenericWsErrorNotification from 'src/core/webservices/showGenericWsErrorNotification';
import FooterGenericButton from './FooterGenericButton';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

function FooterSyncButton({ totalContactsCount, nonSyncContactsCount, actions }) {
  async function onClick() {
    actions.klipsoLeadsSetSyncOngoing(true);

    let error;
    try {
      await saveVisitorRelationList();
    } catch (e) {
      error = e;
    }

    actions.klipsoLeadsSetSyncOngoing(false);

    if (error) {
      showGenericWsErrorNotification(error);
    }
  }

  if (nonSyncContactsCount > 0) {
    return <FooterGenericButton className="klf-sync-btn sync-icon" callback={onClick} />;
  }

  return (
    <FooterGenericButton className="klf-sync-btn klf-allsync-btn" disabled>
      <span className="klf-sync-btn-icon sync-icon">
        {totalContactsCount > 0 && <span className="klf-sync-btn-checkicon check-icon" />}
      </span>
    </FooterGenericButton>
  );
}

FooterSyncButton.propTypes = {
  totalContactsCount: PropTypes.number.isRequired,
  nonSyncContactsCount: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
};

FooterSyncButton.defaultProps = {
  totalContactsCount: 0,
  nonSyncContactsCount: 0,
};

export default FooterSyncButton;
