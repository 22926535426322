import { VALID_DATA_TYPES } from 'data/config/dataConfig';

const LOG_PREF = '[DataFieldsIndex] ';

let fieldsByDataType;

// @see http://stackoverflow.com/questions/35025204/javascript-proxy-support-in-babel
// Due to the limitations of ES5, Proxies cannot be transpiled or polyfilled
// about babel-plugin-proxy "not suitable for production environments because performance impact is huge"
/* const proxyHandler = {
    get: (targetObj, propName) => {
        if (propName in targetObj) {
            return targetObj[propName];
        } else {
            console.error(LOG_PREF+`Unknown field '${propName}', valid fields are:`, targetObj);
            return null;
        }
    },
}; */

export const get = (dataType) => {
  if (!fieldsByDataType) return null;
  return fieldsByDataType[dataType];
};

/**
 * Store field/index matching (= reverse 'order' object)
 * It allows to do for example:
 *         let fields = getFieldsIndex(DATA_TYPE_EXHIBITOR);
 *         console.log(exhibitor[fields.title]);
 * which is clearly more robust than `exhibitor[18]` (subject to change)
 */
export function init(data) {
  fieldsByDataType = {};

  VALID_DATA_TYPES.forEach((dataType) => {
    const fieldsIndex = {};

    if (typeof data[dataType] === 'undefined') {
      // console.warn(LOG_PREF+'Data type not found: ' + dataType);
      return;
    }
    const { order } = data[dataType];

    Object.keys(order).forEach((index) => {
      const field = order[index];
      fieldsIndex[field] = parseInt(index, 10);
    });

    // fieldsByDataType[dataType] = new Proxy(fieldsIndex, proxyHandler);
    fieldsByDataType[dataType] = fieldsIndex;
  });

  // dev options
  if (process.env.NODE_ENV !== 'production') {
    console.log(`${LOG_PREF}Fields index:`, fieldsByDataType);
    global.fieldsIndex = fieldsByDataType;
  }
}
