import React from 'react';
import PropTypes from 'prop-types';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './ContactInfo.scss';

const HARD_CODED_FIELDS = ['Societe', 'Prenom', 'Nom'];

function getIcon(formFieldCode) {
  
  switch (formFieldCode) {
    case 'Societe':
      return <GenericIcon className="kl-fs-icon kl-ci-icon-company suitcase-icon" />;

    case 'Nom':
      return <GenericIcon className="kl-fs-icon kl-ci-icon-name user-icon" />;

    case 'Email':
      return <GenericIcon className="kl-fs-icon kl-ci-icon-other pen-icon" />;

    case 'Pays':
      return <GenericIcon className="kl-fs-icon kl-ci-icon-other globe-icon" />;

    default:
      if (formFieldCode.startsWith('Telephone')) {
        return (
          <GenericIcon
            src={'icons-svg/fiche/telephone.svg'}
            st1Class="phone-row-st1"
            st0Class="phone-row-st0"
            className="kl-fs-icon kl-ci-icon-phone phone-row"
          />
        );
      } else return <span className="kl-fs-icon kl-ci-other" />;
  }
}

function ContactInfo({ contact, readOnlyFields, labels }) {
  const remainingFields = readOnlyFields.filter(
    (field) => HARD_CODED_FIELDS.indexOf(field.Code) === -1
  );

  return (
    <>
      <div className="kl-ci-contact-data-row1">
        {contact.Societe && (
          <div>
            {getIcon('Societe')}
            <span className="kl-ci-company">{contact.Societe || ''}</span>
          </div>
        )}
        {(contact.Prenom || contact.Nom) && (
          <div>
            {getIcon('Nom')}
            <span className="kl-ci-name">
              <span className="kl-ci-firstname">{contact.Prenom || ''}</span>
              <span className="kl-ci-lastname">{contact.Nom || ''}</span>
            </span>
          </div>
        )}
      </div>
      <div className="kl-ci-contact-data-row2">
        {remainingFields.map(function (field) {
          const value = contact[field.Code];
          return !value ? null : (
            <div key={field.Code}>
              {getIcon(field.Code)}
              <span className="kl-ci-other">
                {field.Label ? `${field.Label} : ` : ''} {value}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
}

ContactInfo.propTypes = {
  contact: PropTypes.object.isRequired,
  readOnlyFields: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
};

export default ContactInfo;
