import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';

import { STYLE } from 'data/config/notifConfig';

import * as actions from 'src/store/actions';

import './Notifications.scss';

export const COMPONENT_KEY = 'Notifications';
export const CONTAINER_DOM_ID = 'notifications-container';

const LOG_PREF = '[Notifications] ';

/**
 * @see https://github.com/igorprado/react-notification-system
 */
class Notifications extends Component {
  componentDidMount() {
    this.addNotifs(this.props.notifications || []);
  }

  addNotifs(notifsToAdd) {
    // console.log(LOG_PREF+'Notifs to add: ', notifsToAdd);
    notifsToAdd.forEach((notif) => {
      notif.onRemove = (notif) => {
        // this.removeNotifs([ notif ]);
        this.props.actions.removeNotification(notif.uid);

        // Execute optional callback
        if (typeof notif._onRemove === 'function') {
          notif._onRemove();
        }
      };

      this._notificationSystem.addNotification(notif);
    });
  }

  removeNotifs(notifsUidsToRemove) {
    // console.log(LOG_PREF+'Notifs to remove: ', notifsUidsToRemove);
    notifsUidsToRemove.forEach((id) => {
      this._notificationSystem.removeNotification(id);
    });
  }

  shouldComponentUpdate(nextProps) {
    let currentUids = this.props.notifications.map((notif) => notif.uid),
      newUids = nextProps.notifications.map((notif) => notif.uid);

    // Remove notifs
    this.removeNotifs(currentUids.filter((id) => newUids.indexOf(id) === -1));

    // Add notifs
    this.addNotifs(
      nextProps.notifications.filter((notif) => currentUids.indexOf(notif.uid) === -1)
    );

    if (nextProps.notificationToEdit) {
      this._notificationSystem.editNotification(
        nextProps.notificationToEdit.existing,
        nextProps.notificationToEdit.edited
      );
    }

    return false;
  }

  setNotificationSystem = (ref) => {
    this._notificationSystem = ref;
  };

  render() {
    console.log(LOG_PREF + 'render');
    return (
      <div className="content-font">
        <NotificationSystem ref={this.setNotificationSystem} style={STYLE} allowHTML={true} />
      </div>
    );
  }
}

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
