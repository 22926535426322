import config from 'data/config/config';

import { HIDE_LANG_DIALOG, LANG_CHANGED, SHOW_LANG_DIALOG } from 'src/store/actionTypes';

import { get as getLabels } from 'src/core/Lang';

import { setDialogConfig } from 'src/core/dialog/DialogPersistence';

import { langChanged, updateObject } from 'src/store/reducers/commons';

const getDefaultState = () => ({
  labels: getLabels(),
  isOpen: false,
  supportedLangs: config.SUPPORTED_LANGS,
});

const _toggleDialog = (state, action, value) => {
  setDialogConfig({ isOpen: value, actionName: value ? 'hideChooseLangDialog' : '' });
  return updateObject(state, { isOpen: value });
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case HIDE_LANG_DIALOG:
      return _toggleDialog(state, action, false);
    case LANG_CHANGED:
      return langChanged(state, action);
    case SHOW_LANG_DIALOG:
      return _toggleDialog(state, action, true);

    default:
      return state;
  }
};
