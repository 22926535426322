import { GOOGLE_MAP_PAGE_KEY } from 'src/pages/pagesKeys';

import config from 'data/config/config';

import { isCordovaContext } from 'src/core/util/browser';

import googleMapPage from './googleMapPage';
import reducer from './googleMapPageReducer';

export default {
  mountOnce: true,
  key: GOOGLE_MAP_PAGE_KEY,
  path: '/googleMap',
  component: googleMapPage,
  elId: 'google-map-page',
  getReduxReducer: (state, action) => reducer(state[GOOGLE_MAP_PAGE_KEY], action),
  toggleMode: 'visibility',
  relatedDataToFetch: ['events', 'events_categorie'],
  isGranted: () => config.MAP.GOOGLE_MAP.FEATURE_ENABLED && isCordovaContext(),
};
