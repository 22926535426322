/**
 * Persist the status of PMR has the user set it
 */
import { PMR_CAPABILITY } from 'data/config/mobigeoConfig';
export const StoredManualPMRStatus = (function() {
  const LOCAL_STORAGE_KEY = 'pmr-status';

  const STATUS = {
    ENABLED: 'enabled',
    DISABLED: 'disabled',
  };

  function setEnabled() {
    _set(STATUS.ENABLED);
  }
  function setDisabled() {
    _set(STATUS.DISABLED);
  }
  function _set(value) {
    window.MobiGeo.Map.Route.setPMRStatusPristine(false);
    localStorage.setItem(LOCAL_STORAGE_KEY, value);
  }

  function hasBeenEnabled() {
    return localStorage.getItem(LOCAL_STORAGE_KEY) === STATUS.ENABLED;
  }

  function init() {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    // note: is pristine = has the value been set by the user?
    const isPristine = value !== STATUS.ENABLED && value !== STATUS.DISABLED;
    if (PMR_CAPABILITY) {
      window.MobiGeo.Map.Route.setPMRStatusPristine(isPristine);
    } else {
      window.MobiGeo.Map.Route.setPMRStatus('all');
      window.MobiGeo.Map.Route.setPMRStatusPristine(false);
    }
  }

  return { init, setEnabled, setDisabled, hasBeenEnabled };
})();
