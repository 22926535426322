// Libs
import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';

import { homeToolbarIcon as _homeToolbarIcon } from 'data/config/toolbarConfig';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './HomeToolbar.scss';

class HomeToolbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const homeToolbarIcon =
      _homeToolbarIcon && _homeToolbarIcon(this.props.profile)
        ? _homeToolbarIcon(this.props.profile)
        : [];

    return (
      <div id="home-toolbar">
        {homeToolbarIcon &&
          homeToolbarIcon.length !== 0 &&
          homeToolbarIcon.map((button, index) => (
            <div key={index} className="ht-button" onClick={button.action} title={button.label}>
              {typeof button.badge === 'number' && (
                <span className="ht-button-badge">{button.badge}</span>
              )}
              {button.svg ? (
                <GenericIcon
                  style={button.style}
                  title={button.label}
                  {...button.svg}
                  className={button.className || ''}
                />
              ) : (
                <span
                  style={button.style}
                  title={button.label}
                  className={button.className + ' ' + button.isFontAwesome || ''}
                >
                  {button.isFontProject || ''}
                </span>
              )}
            </div>
          ))}
      </div>
    );
  }
}

/* HomeToolbar.propTypes = {
  labels: PropTypes.object.isRequired,
  associatedPageKey: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool,
  inboxCount: PropTypes.number,
}; */

export default HomeToolbar;
