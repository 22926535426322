'use strict';

export function hasWritePermission(onSuccess) {
  window.plugins.calendar.hasWritePermission(onSuccess);
}

export function requestWritePermission(onSuccess) {
  window.plugins.calendar.requestWritePermission(onSuccess);
}

// create an event silently
export function createCalendarEvent(title, loc, notes, startDate, endDate, onSuccess, onError) {
  window.plugins.calendar.createEvent(title, loc, notes, startDate, endDate, onSuccess, onError);
}

// on iOS the success handler receives the event ID
export function createCalendarEventWithOptions(
  title,
  loc,
  notes,
  startDate,
  endDate,
  options,
  onSuccess,
  onError
) {
  window.plugins.calendar.createEventWithOptions(
    title,
    loc,
    notes,
    startDate,
    endDate,
    options,
    onSuccess,
    onError
  );
}

// create an event interactively
export function createCalendarEventInteractively(
  title,
  loc,
  notes,
  startDate,
  endDate,
  onSuccess,
  onError
) {
  window.plugins.calendar.createEventInteractively(
    title,
    loc,
    notes,
    startDate,
    endDate,
    onSuccess,
    onError
  );
}

// create an event interactively with the calOptions object as shown above
export function createCalendarEventInteractivelyWithOptions() {
  window.plugins.calendar.createEventInteractivelyWithOptions(
    title,
    loc,
    notes,
    startDate,
    endDate,
    options,
    onSuccess,
    onError
  );
}

// delete an event (you can pass nulls for irrelevant parameters). The dates are mandatory and represent a date range to delete events in.
// note that on iOS there is a bug where the timespan must not be larger than 4 years, see issue 102 for details.. call this method multiple times if need be
export function deleteEvent(title, loc, notes, startDate, endDate, onSuccess, onError) {
  window.plugins.calendar.deleteEvent(title, loc, notes, startDate, endDate, onSuccess, onError);
}

export function listCalendars(onSuccess, onError) {
  window.plugins.calendar.listCalendars(onSuccess, onError);
}
