import config from 'data/config/config';

import {
  CONFIG_JSON_LOADED,
  HIDE_INTERSTICIEL,
  HIDE_INTERSTICIEL_CLOSE_BUTTON,
  LANG_CHANGED,
  PROFILE_CHANGED,
  SHOW_INTERSTICIEL,
  SHOW_INTERSTICIEL_CLOSE_BUTTON,
  WINDOW_RESIZED,
} from 'src/store/actionTypes';

import {
  langChanged,
  profileChanged,
  updateObject,
  windowResized,
} from 'src/store/reducers/commons';

import { getAdConfig } from 'src/core/config-json/ConfigJsonManager';
import { get as getProfile } from 'src/core/Profile';
import { get as getLabels, getCurrent as getLang } from 'src/core/Lang';

const getDefaultState = () => ({
  // On app first launch, profile is not set, yet intersticiel must be displayed, so apply default profile if needed
  profile: getProfile() || config.DEFAULT_PROFILE,
  lang: getLang(),
  labels: getLabels(),
  isDisplayed: false,
  isCloseButtonVisible: false,
});

const _profileChanged = (state, action) => {
  // On app initialization, lang file is not loaded yet and ad config depends on it
  if (getLabels()) {
    const newState = profileChanged(state, action, null, true);
    return updateObject(newState, _configJsonLoaded(newState));
  }
  return state;
};

const _showIntersticiel = (state, action) => {
  return updateObject(state, {
    isDisplayed: true,
    next: action.next,
    appIsBooting: action.appIsBooting,
  });
};

const _hideIntersticiel = (state, action) => {
  return updateObject(state, {
    isDisplayed: false,
    isCloseButtonVisible: false,
  });
};

const _showIntersticielCloseButton = (state, action) => {
  return updateObject(state, { isCloseButtonVisible: true });
};

const _hideIntersticielCloseButton = (state, action) => {
  return updateObject(state, { isCloseButtonVisible: false });
};

function _configJsonLoaded(state) {
  const adConfig = getAdConfig(state.profile);
  return updateObject(state, {
    config: adConfig ? adConfig.interstitiels : null,
    files:
      adConfig && adConfig.interstitiels
        ? adConfig.interstitielsFiles[adConfig.interstitiels.name]
        : null,
  });
}

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case PROFILE_CHANGED:
      return _profileChanged(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);
    case SHOW_INTERSTICIEL:
      return _showIntersticiel(state, action);
    case HIDE_INTERSTICIEL:
      return _hideIntersticiel(state, action);
    case SHOW_INTERSTICIEL_CLOSE_BUTTON:
      return _showIntersticielCloseButton(state, action);
    case HIDE_INTERSTICIEL_CLOSE_BUTTON:
      return _hideIntersticielCloseButton(state, action);
    case WINDOW_RESIZED:
      return windowResized(state, action);
    case CONFIG_JSON_LOADED:
      return _configJsonLoaded(state);

    default:
      return state;
  }
};
