// Libs
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Conf
import { DATA_TYPE_NEWPRODUCTS } from 'data/config/dataConfig';
import { sortNewProducts } from 'data/config/sortConfig';

// App modules
import List from 'src/components/list/List';

import './DetailNewProducts.scss';

/**
 * Common component to display related newproducts
 * @param {array}  items
 * @param {object} labels
 * @param {object} store connected actions
 */
class DetailNewProducts extends PureComponent {
  render() {
    const length = Array.isArray(this.props.items) ? this.props.items.length : 0;

    // Skip component display
    if (length === 0) {
      return null;
    }

    const separatorLabel = this.props.labels.data.newproducts[length > 1 ? 'plural' : 'singular'];

    return (
      <div className="detail-newproducts-component">
        <li className="fiche-separator">{separatorLabel}</li>
        <div className="fiche-related-data">
          <List
            isPending={false}
            dataType={DATA_TYPE_NEWPRODUCTS}
            actions={this.props.actions}
            labels={this.props.labels}
            items={sortNewProducts(this.props.items)}
            displayFavorites={false}
            pageKey={this.props.pageKey}
          />
        </div>
      </div>
    );
  }
}

DetailNewProducts.propTypes = {
  items: PropTypes.array,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  pageKey: PropTypes.string.isRequired,
};

export default DetailNewProducts;
