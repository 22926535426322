import config from 'data/config/config';

import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { WS_ERRORS } from '../WS_ERRORS';

export function getUserData(token, cb) {
  const opts = {
    method: 'GET',
    headers: [HEADERS.JSON_CONTENT_TYPE],
  };

  fetchHelper(
    `${config.USER_DATA.WS_URL}/${token}`,
    opts,
    true, // parse response as json
    function onSuccess(data) {
      cb(null, data.profile);
    },
    function onFailure(error, message) {
      cb(WS_ERRORS.NETWORK);
    },
    false // showModalOnError
  );
}
