import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { EXHIBITOR_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

/**
 * Common component to display related exhibitor
 * @param {array} exhibitor
 * @param {object} labels
 * @param {object} store connected actions
 */
class DetailExhibitor extends PureComponent {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.actions.navigate(EXHIBITOR_PAGE_KEY, { id: this.props.exhibitor.id });
  }

  render() {
    if (!this.props.exhibitor) {
      // Skip component rendering
      return null;
    }

    return (
      <div className="activity detail-exhibitor clickable" onClick={this.onClick}>
        <div className="prop-img">
          <div className="prop-left label">
            <div className="color-grey-dark">
              <span>{this.props.labels.data.exhibitors.singular}</span>
            </div>
          </div>
          <div className="prop-right name-label flex-ellipsis">
            <div className="activities-content ">
              <span className="link colored-link">{this.props.exhibitor.title}</span>
            </div>
            <div className="activities-content ">
              <span>{this.props.exhibitor.lump.metro && `${this.props.labels.exhibitor.metro} : ${this.props.exhibitor.lump.metro}`}</span>
            </div>
            <div className="activities-content ">
              {this.props.exhibitor.lump.parcours.length > 0 && 
                <span>
                {this.props.labels.exhibitor.parcours} : 
                {this.props.exhibitor.lump.parcours.map((parcour, index) => {
                  return index < this.props.exhibitor.lump.parcours.length - 1 ? ` ${parcour}, ` : ` ${parcour}`
                })}
                </span>
              }
            </div>

            {/* address */}
            {this.props.exhibitor.address && (
              <div className="activities-content">
                {this.props.exhibitor.address && (
                  <div dangerouslySetInnerHTML={{ __html: this.props.exhibitor.address }} />
                )}
                {this.props.exhibitor.postal_code && (
                  <div dangerouslySetInnerHTML={{ __html: this.props.exhibitor.postal_code }} />
                )}
                {this.props.exhibitor.city && (
                  <div dangerouslySetInnerHTML={{ __html: this.props.exhibitor.city }} />
                )}
              </div>
            )}

          </div>
          <div className="prop-right-fleche">
            <GenericIcon
              st0Class="chevron-right-icon-st0"
              src="icons-svg/control/chevron-droit-solid.svg"
              className="fleche chevron-right-icon"
            />
          </div>
        </div>
      </div>
    );
  }
}

DetailExhibitor.propTypes = {
  exhibitor: PropTypes.object,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default DetailExhibitor;
