import React from 'react';
import PropTypes from 'prop-types';

import { SEARCH_MIN_CHARS } from 'data/config/searchConfig';
import { DATA_TYPE_CONTACTS } from 'data/config/dataConfig';
import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';
// import STATUS from "src/store/fetchStatuses";

import DetailDataList from 'src/components/detail-data-list/DetailDataList';
import SearchField from 'src/components/search/SearchField';

import './Contacts.scss';

class Contacts extends React.Component {
  constructor(props) {
    super(props);

    this.performSearch = this.performSearch.bind(this);
    this.clearSearchResults = this.clearSearchResults.bind(this);
  }

  performSearch(value) {
    this.props.actions.performSearch(value, USER_DATA_PAGE_KEY, [DATA_TYPE_CONTACTS]);
  }

  clearSearchResults() {
    this.props.actions.clearSearchResults(USER_DATA_PAGE_KEY);
  }

  render() {
    let contacts;
    if (this.props.searchResults) {
      contacts = this.props.searchResults[DATA_TYPE_CONTACTS];
    } else {
      contacts = this.props.contacts;
    }

    return (
      <div className="ud-contacts">
        <SearchField
          labels={this.props.labels}
          placeholder={this.props.labels.userData.contactsSearchPlaceholder}
          clearResults={this.clearSearchResults}
          perform={this.performSearch}
          minChars={SEARCH_MIN_CHARS}
          hasClearButton
          hasCancelButton={false}
        />

        <DetailDataList
          dataType={DATA_TYPE_CONTACTS}
          items={Array.isArray(contacts) ? contacts : []}
          // isPending={Array.isArray(contacts) ? false : contacts.status === STATUS.PENDING}
          actions={this.props.actions}
          labels={this.props.labels}
          pageKey={USER_DATA_PAGE_KEY}
        />
      </div>
    );
  }
}

Contacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  searchResults: PropTypes.object,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default Contacts;
