import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash-custom';

import { MOBIGEO_PAGE_KEY } from 'src/pages/pagesKeys';

import Menu from 'src/components/menu/Menu';
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import MobilespotButton from 'src/components/mobilespot-button/MobilespotButton';
import AdBanner from 'src/components/ad-banner/AdBanner';

import { init as initFeatureStatus } from 'src/core/mapFeatures';
import { fetchHappeningsDataLumpFromDB as happeningsData } from 'src/core/happenings/happenings';

import * as actions from 'src/store/actions';
import MobiGeoAssetsProvider from './MobiGeoAssetsProvider';
import { shouldAutoStart } from './locationHelper';
import config from 'data/config/config';

import MobigeoContext from './MobigeoContext';

import './MobigeoPage.scss';

const LOG_PREF = '[MobigeoPage] ';

const getMobiGeoLoadOptions = (props) => ({
  lang: props.labels.id,
});

const getMobiGeoMapCreateOptions = () => ({
  showWatermark: false,
});

class MobigeoPage extends Component {
  constructor(props) {
    super(props);

    this.pageKey = MOBIGEO_PAGE_KEY;

    // @see ../../README.md#using-global-variables
    this.MobiGeo = window.MobiGeo;
    if (this.MobiGeo) {
      this.MobiGeo.setLogLevel(config.MAP.MOBIGEO.LOG_LEVEL);
      this.bindEventHandlers();
    }
    this.containerId = 'mobigeo-container';

    this.loadDataset = debounce(this._loadDataset.bind(this), 150);
  }

  /**
   * Update string visible in browser tab/history/favorites
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(this.getPageTitle());
    }
  }

  /**
   * String displayed in app toolbar
   * @return {string}
   */
  getPageTitle() {
    return this.props.labels.mobigeo.title;
  }

  /**
   * Listen to MobiGeo events
   */
  bindEventHandlers() {
    // Mobigeo loaded
    this.MobiGeo.Map.on('ready', () => {
      MobigeoContext.setStatusLoaded();

      if (this.props.shouldRestart) {
        this.restart();
      } else {
        console.log(`${LOG_PREF}Map has successfully been loaded`);

        // Refresh features availability
        initFeatureStatus();

        // Update state (e.g shouldRestart=false)
        this.props.actions.mobigeoLoaded();
        this.props.actions.startSavedFromUrlItinerary();
      }
    });
  }

  restart() {
    this.props.actions.mobigeoIsRestarting();
    MobigeoContext.setStatusRestarting();
    console.log(`${LOG_PREF}Map restart`);
    this.loadDataset();
  }

  _loadDataset() {
    if (this.props.isDataReady !== true) {
      console.log(`${LOG_PREF}Skipping map loading because data is not available yet`);
      return;
    }

    if (!MobigeoContext.isLoading()) {
      console.info(`${LOG_PREF}Load map`);
      MobigeoContext.setStatusLoading();
      this.MobiGeo.Location.autoStart = shouldAutoStart();
      this.MobiGeo.Map.POI.disablePopups = true;
      this.MobiGeo.setProviders(MobiGeoAssetsProvider);

      this.MobiGeo.load(this.props.mobigeoApiKey, getMobiGeoLoadOptions(this.props), (err) => {
        if (err) {
          console.error(`${LOG_PREF}MobiGeo failed to unlock data set because: ${err}`);
          MobigeoContext.setStatusNotLoaded();
          return;
        }
        this.MobiGeo.Map.create(
          document.getElementById(this.containerId),
          getMobiGeoMapCreateOptions(),
          (err) => {
            if (err) {
              console.warn(`${LOG_PREF}MobiGeo.Map.create thrown error ${err}`);
              this.props.actions.mobigeoErrorThrown(err, 'MobiGeo.Map.create');

              if (err === 'MAP_ERROR_INVALID_PARENT_SIZE') {
                const delay = 1;
                console.info(`${LOG_PREF}Map container is too small, retry in ${delay} second(s).`);
                window.setTimeout(this.loadDataset, 1 * 1000);
              }
              MobigeoContext.setStatusNotLoaded();
            }
            // in case of success, see 'ready' event handler (see bindEventHandlers)
          }
        );
      });
    }
  }

  UNSAFE_componentWillMount() {
    this.toggleLoaderAccordingToDataReadyStatus(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.toggleLoaderAccordingToDataReadyStatus(nextProps);
  }

  toggleLoaderAccordingToDataReadyStatus(props) {
    if (props.isDataReady !== true) {
      this.props.actions.showFullLoader();
    } else {
      this.props.actions.hideFullLoader();
    }
  }

  /**
   * Note: returning true does not result in MobiGeo loading again from scratch.
   */
  shouldComponentUpdate(nextProps) {
    if (nextProps.isDataReady !== true) {
      return false;
    }

    // Window has been resize
    if (this.props.lastResize !== nextProps.lastResize) {
      if (MobigeoContext.isNotLoaded()) {
        this.loadDataset();
      } else {
        this.MobiGeo.Map.resize();
      }

      if (this.props.orientation !== nextProps.orientation) {
        return true;
      }

      return false;
    }

    // Data or assets have been updated
    /* if (this.props.shouldRestart !== nextProps.shouldRestart) {
            if (nextProps.shouldRestart === true) {
                MobigeoContext.setStatusNotLoaded(); // => MobiGeo reload
            }
        } */

    return true;
  }

  /**
   * Load MobiGeo dataset if data was not ready when the component has been mounted.
   * (e.g direct access to the page via url)
   */
  componentDidUpdate() {
    if (this.props.shouldRestart) {
      this.restart();
    }
    this.setDocumentContext();
  }

  /**
   * Load MobiGeo dataset if data is ready when the component is mounted.
   * (e.g coming from another page)
   */
  componentDidMount() {
    this.loadDataset();
    this.setDocumentContext();
  }

  render() {
    console.log(`${LOG_PREF}render`);

    // Always keep that page's DOM
    // if (!this.props.isVisible) return null;

    if (this.props.isDataReady !== true) {
      return null;
    }
    return (
      <>
        <Menu
          actions={this.props.actions}
          labels={this.props.labels}
          profile={this.props.profile}
          options={this.props.menuOptions}
          associatedPageKey={this.pageKey}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          actions={this.props.actions}
          title={this.getPageTitle()}
          pageKey={this.pageKey}
          profile={this.props.profile}
          hasBackButton={this.props.backButtonInToolbar}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={this.props.searchButtonInToolbar}
          hasMenuButton={this.props.menuButtonInToolbar}
        />

        {happeningsData() && (
          <AdBanner
            ad={happeningsData()}
            adFiles={happeningsData().files}
            currentLang={this.props.labels.id}
            actions={this.props.actions}
          />
        )}

        <div
          id="mobigeo-container-wrapper"
          //className="content-below-apptoolbar"
          style={{ height: happeningsData() ? 'calc(100% - 130px)' : 'calc(100% - 48px)' }}
        >
          <>
            <div id={this.containerId} />
            {this.props.hasMobileSpotButton && <MobilespotButton labels={this.props.labels} />}
          </>
        </div>
      </>
    );
  }
}

MobigeoPage.propTypes = {
  lastResize: PropTypes.number,
  isDataReady: PropTypes.bool,
  shouldRestart: PropTypes.bool,
  mobigeoApiKey: PropTypes.string.isRequired,
  hasMobileSpotButton: PropTypes.bool,
  // Common page props
  menuOptions: PropTypes.object.isRequired,
  profile: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isActive: PropTypes.func.isRequired,
  setDocumentContext: PropTypes.func.isRequired,
  isLocationEnabled: PropTypes.bool,
  isVisible: PropTypes.bool, // set by togglePageAfterNavigation common reducer function
  isAppVisible: PropTypes.bool,
  // toolbar
  hasToolbar: PropTypes.bool,
  homeButtonInToolbar: PropTypes.bool,
  backButtonInToolbar: PropTypes.bool,
  searchButtonInToolbar: PropTypes.bool,
  favButtonInToolbar: PropTypes.bool,
  menuButtonInToolbar: PropTypes.bool,
  routingDestinationPoi: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  return state[MOBIGEO_PAGE_KEY];
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(MobigeoPage);
