import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';

import { LOCATION_BUTTON_PROPS } from 'data/config/menuConfig';
import { MOBIGEO_PAGE_KEY } from 'src/pages/pagesKeys';
import { StoredManualLocationStatus } from 'src/pages/mobigeo/locationHelper';
import * as actions from 'src/store/actions';

function noop() {}

class ToggleLocationItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.isDisclaimerDisplayed = false;
    this.toggleLocationCapability = this.toggleLocationCapability.bind(this);
  }

  toggleLocationCapability(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.props.isLocationReady === true) {
      this.props.actions.stopLocation();
    } else {
      StoredManualLocationStatus.setEnabled();
      this.props.actions.requestLocationCapabilities();
    }
  }

  render() {
    if (!global.isCordovaContext) {
      return null;
    }

    // CONTAINER
    const { entry } = this.props;
    const hasContainerStyle =
      entry.style && typeof entry.style.container === 'object' && entry.style.container !== null;
    const attributes = {};
    const classes = [];

    // css
    if (hasContainerStyle && entry.style.container.style) {
      attributes.style = entry.style.container.style;
    }
    // if (isDisabled === true) {
    //    classes.push('menu-item-disabled');
    // }
    if (hasContainerStyle && entry.style.container.className) {
      classes.push(entry.style.container.className);
    }
    attributes.className = classes.join(' ');
    // ICON
    const hasIconStyle =
      entry.style && typeof entry.style.icon === 'object' && entry.style.icon !== null;
    const iconClasses = ['menu-icon'];

    if (hasIconStyle && entry.style.icon.className) {
      iconClasses.push(entry.style.icon.className);
    }

    return (
      <ListItem {...attributes} onClick={this.toggleLocationCapability}>
        <div className="menu-item content-font">
          <span
            className={iconClasses.join(' ')}
            style={hasIconStyle && entry.style.icon.style ? entry.style.icon.style : null}
          >
            {hasIconStyle && typeof entry.style.icon.webfont === 'string'
              ? entry.style.icon.webfont
              : null}
          </span>

          <div className="menu-text">{this.props.label ? this.props.label : entry.label}</div>
        </div>
        <div className="menu-item content-font menu-item-switch">
          {/* see:
                         https://github.com/markusenglund/react-switch
                         https://react-switch.netlify.com/
                     */}
          <Switch
            onChange={noop}
            checked={this.props.isLocationReady}
            className="react-switch"
            {...LOCATION_BUTTON_PROPS}
          />
        </div>
      </ListItem>
    );
  }
}

ToggleLocationItem.propTypes = {
  actions: PropTypes.object.isRequired,
  isLocationReady: PropTypes.bool,
};

ToggleLocationItem.defaultProps = {
  isLocationReady: false,
};

const mapStateToProps = (state, ownProps) => state[MOBIGEO_PAGE_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(ToggleLocationItem);
