import { ENV } from 'data/config/config';

import { scan } from 'src/core/cordova/BarCodeScanner';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

import { getBindedActions } from 'src/store/bindedActions';
import { get as getLabels } from 'src/core/Lang';

const LOG_PREF = '[ContactsBadgeHelper] ';

global.mockBadge = false;

/**
 * Uncrypt the content of badge (QR code)
 * @param  {string}
 * @return {string}
 */
const uncrypt = (string) =>
  new Promise(function(resolve, reject) {
    if (!string) {
      console.warn(`${LOG_PREF}No string to uncrypt`);
      resolve(null);
      return;
    }
    if (
      !window.mobilespotBadgeScan ||
      typeof window.mobilespotBadgeScan.uncryptKlipsoBadge !== 'function'
    ) {
      console.error(`${LOG_PREF}cordova-plugin-mobilespot-app is missing`);
      reject();
      return;
    }

    window.mobilespotBadgeScan.uncryptKlipsoBadge(string, resolve, reject);
  });

/**
 * String format is expected as:
 * code&value1#value2#value3#...
 *
 * e.g: "HW744RQ86H&Ribeiro#CHRISTINE#VACATAIRE#ducat.c@live.fr#"
 *
 * see doc: https://mobile-spot.atlassian.net/wiki/spaces/KLEAD/pages/113704964/Current+App+and+testing.
 *
 * @param  {string} string
 * @return {null|object}
 */
function splitUncryptedString(string) {
  if (typeof string !== 'string' || string.length === 0) {
    return null;
  }
  const splitted = string.split('&');
  const id = splitted[0];
  const values = splitted[1].split('#');
  const content = {
    id,
  };
  content.firstName = values[0] && values[0];
  content.lastName = values[1] && values[1];
  content.organizationName = values[2] && values[2];
  content.email = values[3] && `@${values[3].split('@')[1]}`;
  return content;
}

// For test purpose
if (ENV === 'dev') {
  global.splitUncryptedString = splitUncryptedString;
}
function unHandledQRCode() {
  getBindedActions().showNotification({
    message: getLabels().contacts.qrCodeUnHnadled,
    level: NotificationLevels.WARNING,
  });
}
export async function performScan(callback) {
  // DEV MODE
  /*
    if ((!global.isCordovaContext || global.mockBadge === true) && ENV === 'dev') {
        let content = splitUncryptedString(getTestDataBadge());

        callback({
            result: content,
            resultFormat: 'QR_CODE',
        });
        return;
    }
    */
  try {
    const result = await scan();
    if (!result) {
      getBindedActions().showNotification({
        message: getLabels().contacts.scanError,
        level: NotificationLevels.WARNING,
      });
      return;
    }

    let content;
    if (result.cancelled == true) {
      callback({
        status: 'cancelled',
      });
      return;
    }
    if (result.text && result.text.length && result.text[0] === '#') {
      // crypted badge (when content starts with a '#')
      // see: cordova/src-app-plugins/cordova-plugin-mobilespot-app/src/android/KlipsoLeadsQrCodeDecoder.java
      content = await uncrypt(result.text).catch((e) => {
        console.error(`Failed to uncrypt QR code: ${e}`);
        unHandledQRCode();
      });
      content = splitUncryptedString(content);
    } else {
      unHandledQRCode();
      return;
    }
    callback({
      status: 'success',
      content,
    });
  } catch (e) {
    getBindedActions().showNotification({
      message: getLabels().contacts.scanDenied,
      level: NotificationLevels.WARNING,
    });
  }
}
