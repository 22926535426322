import { DISABLE_FAVORITE_ICON } from 'data/config/listConfig';

import {
  CONFIG_JSON_LOADED,
  POLL_CONFIG_LOADED,
  ALL_FAVORITES_DELETED,
  CLEAR_SEARCH_RESULTS,
  DATA_ASSETS_UPDATED,
  HAS_NAVIGATED,
  KEYBOARD_TOGGLED,
  LANG_CHANGED,
  PROFILE_CHANGED,
  SEARCH_PERFORMED,
  TOGGLE_FAVORITE,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  USER_DATA_UPDATED,
} from 'src/store/actionTypes';

import {
  allFavoritesDeleted,
  configJsonLoaded,
  pollConfigLoaded,
  getPageCommonDefaultState,
  togglePageAfterNavigation,
  langChanged,
  profileChanged,
  setSearchPlaceHolder,
  toggleLocationStatus,
  toggleMenu,
  updateKeyboardState,
  updateObject,
  updatePageState,
  userDataUpdated,
} from 'src/store/reducers/commons';

import STATUS from 'src/store/fetchStatuses';

import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';

const getDefaultState = () => ({
  ...getPageCommonDefaultState(SEARCH_PAGE_KEY),
  favIconDisabled: DISABLE_FAVORITE_ICON === true,
});

function _searchPerformed(state, action) {
  if (action.pageKey === SEARCH_PAGE_KEY) {
    return updateObject(state, {
      isPending: action.status === STATUS.PENDING,
      searched: action.searched,
      results: action.results,
      favorites: action.favorites,
      relaunch: false,
    });
  }
  return state;
}

function _clearSearchResults(state, action) {
  if (action.pageKey === SEARCH_PAGE_KEY) {
    return updateObject(state, {
      isPending: false,
      results: null,
      searched: '',
    });
  }
  return state;
}

const _toggleFavorite = (state, action) => updateObject(state, { favorites: action.favorites });

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);
    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, SEARCH_PAGE_KEY);
    case ALL_FAVORITES_DELETED:
      return allFavoritesDeleted(state);
    case CLEAR_SEARCH_RESULTS:
      return _clearSearchResults(state, action);
    case DATA_ASSETS_UPDATED:
      return setSearchPlaceHolder(state, action, SEARCH_PAGE_KEY);
    case KEYBOARD_TOGGLED:
      return updateKeyboardState(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);
    case POLL_CONFIG_LOADED:
      return pollConfigLoaded(state, action);
    case PROFILE_CHANGED:
      return profileChanged(state, action, SEARCH_PAGE_KEY);
    case SEARCH_PERFORMED:
      return _searchPerformed(state, action, SEARCH_PAGE_KEY);
    case TOGGLE_FAVORITE:
      return _toggleFavorite(state, action);
    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);
    case TOGGLE_MENU:
      return toggleMenu(state, action, SEARCH_PAGE_KEY);
    case UPDATE_PAGE_STATE:
      return updatePageState(state, action, SEARCH_PAGE_KEY);
    case USER_DATA_UPDATED:
      return userDataUpdated(state, action);

    default:
      return state;
  }
};
