// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Config
import { NOTES_PAGE_KEY } from 'src/pages/pagesKeys';

// App modules
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import * as actions from 'src/store/actions';
import NotesContent from './NotesContent';

import './NotesPage.scss';

const LOG_PREF = '[NotesPage] ';

class NotesPage extends Component {
  constructor(props) {
    super(props);
    this.pageKey = NOTES_PAGE_KEY;
  }

  /**
   * Update string visible in browser tab/history/notes
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(this.props.labels.notes.pageTitle);
    }
  }

  /**
   * String displayed in app toolbar
   * @return {string}
   */
  getPageTitle() {
    return this.props.labels.notes.pageTitle;
  }

  componentDidMount() {
    this.props.actions.fetchNotes();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shouldFetch === true) {
      this.props.actions.fetchNotes();
    }
  }

  componentDidUpdate() {
    this.setDocumentContext();
  }

  render() {
    console.log(`${LOG_PREF}render`);

    if (!this.props.isVisible) {
      return null;
    }

    return (
      <>
        <Menu
          options={this.props.menuOptions}
          actions={this.props.actions}
          labels={this.props.labels}
          profile={this.props.profile}
          associatedPageKey={this.pageKey}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          actions={this.props.actions}
          title={this.getPageTitle(this.props)}
          pageKey={this.pageKey}
          profile={this.props.profile}
          synchroFavBtnStatus={this.props.synchroFavBtnStatus}
          isSynchroFavFeatureEnabled={this.props.isSynchroFavFeatureEnabled}
          hasBackButton={this.props.backButtonInToolbar}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={this.props.searchButtonInToolbar}
          hasMenuButton={this.props.menuButtonInToolbar}
        />

        <NotesContent
          actions={this.props.actions}
          data={this.props.data}
          notes={this.props.notes}
          labels={this.props.labels}
          isPending={this.props.isPending}
          itemNotFound={this.props.itemNotFound}
          profile={this.props.profile}
          isLoggedIn={this.props.isLoggedIn}
          favIconDisabled
        />
      </>
    );
  }
}

NotesPage.propTypes = {
  data: PropTypes.object,
  notes: PropTypes.object,
  isPending: PropTypes.bool,
  itemNotFound: PropTypes.bool,
  isSynchroFavFeatureEnabled: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  // Common page props
  menuOptions: PropTypes.object.isRequired,
  profile: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isActive: PropTypes.func.isRequired,
  queryString: PropTypes.string.isRequired,
  setDocumentContext: PropTypes.func.isRequired,
  isLocationEnabled: PropTypes.bool,
  isVisible: PropTypes.bool, // set by togglePageAfterNavigation common reducer function
  // toolbar
  hasToolbar: PropTypes.bool,
  synchroFavBtnStatus: PropTypes.string,
  homeButtonInToolbar: PropTypes.bool,
  backButtonInToolbar: PropTypes.bool,
  searchButtonInToolbar: PropTypes.bool,
  favButtonInToolbar: PropTypes.bool,
  menuButtonInToolbar: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return state[NOTES_PAGE_KEY];
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesPage);
