import config from 'data/config/config';

import { get as getLabels } from 'src/core/Lang';

import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';

import { getBindedActions } from 'src/store/bindedActions';

import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

function onRequestSuccess(json) {
  const status = {};

  // Show notification
  if (json.success) {
    getBindedActions().showNotification({
      message: getLabels().notes.exportSuccess,
      level: NotificationLevels.SUCCESS,
    });
    getBindedActions().hideFormModal();
  } else {
    let message = '';
    switch (json.message) {
      case 'INVALID_CREDENTIALS':
        message = getLabels().notes.errors.INVALID_CREDENTIALS;
        break;
      case 'INVALID_SMTP_CONFIGURATION':
        message = getLabels().notes.errors.INVALID_SMTP_CONFIGURATION;
        break;
      default:
        message = getLabels().notes.errors.DEFAULT;
    }

    getBindedActions().showNotification({
      message,
      level: NotificationLevels.WARNING,
    });
  }
}

function onRequestFailure(failReason) {
  console.error('sendEmailFailure', failReason);

  // Show failure notification
  const errorLabel = getLabels().notes.errors.DEFAULT;
  getBindedActions().showNotification({
    message: errorLabel,
    level: NotificationLevels.WARNING,
  });

  // Dispatch failure
  const status = {
    error: {
      label: errorLabel,
      reason: failReason,
    },
  };
}

export function sendMail(form, mailContent) {
  const name = getLabels().notes.pageTitle;
  const mail = {
    expeditor: config.SEND_EXPORTED_NOTES_MAIL.EXPEDITOR,
    recipient: form.email,
    subject: getLabels().common.appTitle + (name ? config.APP_TITLE_SEPARATOR + name : ''),
    content: mailContent,
  };
  const body = JSON.stringify({
    mailObject: mail,
    key: config.SEND_EXPORTED_NOTES_MAIL.KEY,
  });

  fetchHelper(
    config.SEND_EXPORTED_NOTES_MAIL.WS_URL,
    {
      method: 'POST',
      body,
      headers: [HEADERS.JSON_CONTENT_TYPE],
    },
    true, // is json
    (json) => {
      onRequestSuccess(json);
    },
    (failReason) => {
      onRequestFailure(failReason);
    },
    false // no modal on error
  );
}
