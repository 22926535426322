import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { LOGIN_PAGE_KEY, USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';

import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import LoginContent from './LoginContent';

import * as actions from 'src/store/actions';

import './LoginPage.scss';

const LOG_PREF = '[LoginPage]';

class LoginPage extends Component {
  pageKey = LOGIN_PAGE_KEY;

  /**
   * Update string visible in browser tab/history/favorites
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(this.props.labels.login.title);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isVisible) {
      if (this.props.isLogged === true) {
        if (!prevProps.isLogged) {
          // Login success
          if (this.props.nextRoute) {
            this.props.actions.navigate(
              this.props.nextRoute.pageKey,
              this.props.nextRoute.pageProps
            );
            if (this.props.nextAction) {
              this.props.actions[this.props.nextAction.name](this.props.nextAction.params);
            }
          } else if (this.props.nextAction) {
            this.props.actions[this.props.nextAction.name](this.props.nextAction.params);
          } else {
            this.props.actions.navigate(USER_DATA_PAGE_KEY);
          }
        } else {
          // User is already logged-in and navigate to LoginPage (e.g navigate back after login success)
          // Ticket PBC-35
          this.props.actions.navigateToHome();
        }
      }
    }
  }

  render() {
    console.log(LOG_PREF + 'render');
    if (!this.props.isVisible) return null;

    return (
      <>
        <Menu
          options={this.props.menuOptions}
          actions={this.props.actions}
          labels={this.props.labels}
          profile={this.props.profile}
          associatedPageKey={this.pageKey}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          actions={this.props.actions}
          title={this.props.labels.login.title}
          pageKey={this.pageKey}
          profile={this.props.profile}
          hasBackButton={this.props.backButtonInToolbar}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={this.props.searchButtonInToolbar}
          hasMenuButton={this.props.menuButtonInToolbar}
        />

        <LoginContent
          login={this.props.login}
          userData={this.props.userData}
          isLogging={this.props.isLogging}
          isLogged={this.props.isLogged}
          urls={this.props.urls}
          urlsTarget={this.props.urlsTarget}
          urlsOpenInInAppBrowser={this.props.urlsOpenInInAppBrowser}
          error={this.props.error}
          logout={this.props.logout}
          keyboardHeight={this.props.keyboardHeight}
          labels={this.props.labels}
          actions={this.props.actions}
        />
      </>
    );
  }
}

LoginPage.propTypes = {
  login: PropTypes.string,
  userData: PropTypes.object,
  isLogging: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool.isRequired,
  urls: PropTypes.object,
  error: PropTypes.string,
  // Common
  keyboardHeight: PropTypes.number,
  adConfig: PropTypes.object,
  twocolumns: PropTypes.bool,
  menuOptions: PropTypes.object.isRequired,
  profile: PropTypes.string,
  isActive: PropTypes.func.isRequired,
  setDocumentContext: PropTypes.func.isRequired,
  isLocationEnabled: PropTypes.bool,
  isVisible: PropTypes.bool, // set by togglePageAfterNavigation common reducer function
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[LOGIN_PAGE_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
