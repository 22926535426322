function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
export const saveAndOpenbase64AsFile = (folderpath, filename, content, contentType) => {
  // Convert the base64 string in a Blob
  const DataBlob = b64toBlob(content, contentType);

  window.resolveLocalFileSystemURL(folderpath, function(dir) {
    dir.getFile(filename, { create: true }, function(file) {
      file.createWriter(
        function(fileWriter) {
          fileWriter.write(DataBlob);
          const finalPath = folderpath + filename;
          cordova.plugins.fileOpener2.open(
            finalPath, // You can also use a Cordova-style file uri: cdvfile://localhost/persistent/Downloads/starwars.pdf
            contentType,
            {
              error(e) {
                console.log(`Error status: ${e.status} - Error message: ${e.message}`);
              },
              success() {},
            }
          );
        },
        function() {
          alert(`Unable to save file in path ${folderpath}`);
        }
      );
    });
  });
};

export const saveBase64AsFile = (folderpath, filename, content, contentType, cb) => {
  // Convert the base64 string in a Blob
  const DataBlob = b64toBlob(content, contentType);

  window.resolveLocalFileSystemURL(folderpath, function(dir) {
    dir.getFile(filename, { create: true }, function(file) {
      file.createWriter(
        function(fileWriter) {
          fileWriter.onwrite = function(evt) {
            const finalPath = folderpath + filename;
            cb(finalPath);
          };
          fileWriter.write(DataBlob);
        },
        function() {
          alert(`Unable to save file in path ${folderpath}`);
        }
      );
    });
  });
};
