import moment from 'moment';

import config from 'data/config/config';

import fetchHelper from 'src/core/util/FetchHelper';
import { getBindedActions } from 'src/store/bindedActions';

import { LOG_PREF, isEnabled } from 'src/core/contributions-feed/ContributionsFeedManager';

export const ERRORS = {
  NETWORK: 'CONTRIBUTIONS_FEED_NETWORK_ERROR',
  SERVER: 'CONTRIBUTIONS_FEED_SERVER_ERROR',
};

const LOCALSTORAGE_KEY = 'contributionsFeed';

/**
 * Sub-mobule responsible for persistence
 * @type {Object}
 */
export const persistence = {
  get() {
    const value = localStorage.getItem(LOCALSTORAGE_KEY);
    return value ? JSON.parse(value) : null;
  },
  set(value) {
    const timestamp = moment().unix();
    const data = {
      date: timestamp,
      value,
    };

    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(data));
  },
};

/**
 * Call the API to submit the poll
 * @param  {object}   data
 */
export function fetch(userAction) {
  if (isEnabled()) {
    const opts = {
      method: 'GET',
    };

    const cache = persistence.get();
    const now = moment().unix();
    const lastRecordedAt = cache ? Number(cache.date) : null;

    if (!lastRecordedAt || now - lastRecordedAt > 5) {
      console.log(`${LOG_PREF}Fetching contributions feed at${config.CONTRIBUTIONS_FEED.URL}`);

      // getBindedActions().activateContributionsRefresh(false)

      fetchHelper(
        `${config.CONTRIBUTIONS_FEED.URL}?t=${moment().valueOf()}`,
        opts,
        true, // isJson
        _onFetchSuccess,
        () => {
          _onFetchFailure(userAction);
        },
        false // showModalOnError
      );
    }
  }
}

function _onFetchSuccess(data) {
  getBindedActions().contributionsFeedLoaded(data);
  // setTimeout(() => getBindedActions().activateContributionsRefresh(true), 10 * 1000)
}

function _onFetchFailure(userAction) {
  if (userAction) {
    getBindedActions().setContributionsFeedError(ERRORS.NETWORK);
  }
}
