import React from 'react';
import PropTypes from 'prop-types';

import { CATEGORIES_DATA_TYPE } from 'data/config/dataConfig';
import { DISABLE_FAVORITE_BY_DATATYPE } from 'data/config/listConfig';

import List from 'src/components/list/List';
import TypeBar from 'src/components/type-bar/TypeBar';
import NoResult from 'src/components/no-result/NoResult';

function ListsPerDataType({
  items,
  searched,
  isPending,
  notes,
  favorites,
  favIconDisabled,
  userData,
  actions,
  labels,
  pageKey,
  isNotesList,
}) {
  if (items !== null && typeof items === 'object') {
    const listComponents = [];
    const data = isNotesList ? notes : items;
    Object.keys(data).forEach((dataType) => {
      // Skip empty categories
      if (items[dataType] && items[dataType].length) {
        const listProps = {
          dataType,
          items: items[dataType],
          notes: notes,
          notesStr: JSON.stringify(notes),
          favorites,
          actions,
          labels,
          isPending,
          highlight: searched,
          userData,
          pageKey,
          displayFavorites: favIconDisabled === true ? false : !DISABLE_FAVORITE_BY_DATATYPE.includes(dataType),
          isNotesList: isNotesList
          // isClosed   : totalCount > SEARCH_HIDE_LIST_IF_TOTAL_ABOVE,
        };

        // Skip categories who have no item
        if (CATEGORIES_DATA_TYPE.indexOf(dataType) !== -1) {
          listProps.items = listProps.items.filter((item) => item.counter > 0);
        }

        if (listProps.items && listProps.items.length > 0) {
          listComponents.push(
            <TypeBar
              key={dataType}
              label={labels.data[dataType][listProps.items.length > 1 ? 'plural' : 'singular']}
              count={listProps.items.length}
            >
              <List {...listProps} />
            </TypeBar>
          );
        }
      }
    });

    if (listComponents.length > 0) {
      return listComponents;
    }
  }

  return <NoResult labels={labels} />;
}

ListsPerDataType.propTypes = {
  items: PropTypes.object,
  searched: PropTypes.string,
  isPending: PropTypes.bool,
  favorites: PropTypes.object,
  favIconDisabled: PropTypes.bool,
  userData: PropTypes.object,
  actions: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};

export default ListsPerDataType;
