import { CHOOSE_PROFILE_PAGE_KEY } from 'src/pages/pagesKeys';

import { getHomePage } from 'data/config/config';
import TwoColumnsModeManager from 'src/core/navigation/TwoColumnsModeManager';

/**
 * Determine what navigation is performed once user clicked on a profile button
 * @param  {string} selectedProfile
 * @param  {object} actions
 * @param  {object} thenNavigateTo: allows to override default navigation
 */
export function navigateAfterClick(selectedProfile, actions, thenNavigateTo) {
  actions.showIntersticiel(true, function() {
    let navigateTo;

    if (
      typeof thenNavigateTo === 'object' &&
      thenNavigateTo !== null &&
      thenNavigateTo.pageKey &&
      thenNavigateTo.pageKey !== CHOOSE_PROFILE_PAGE_KEY
    ) {
      navigateTo = thenNavigateTo;
    } else {
      navigateTo = getHomePage(selectedProfile);
    }

    TwoColumnsModeManager.autoEnable();

    // Perform navigation
    actions.navigate(navigateTo.pageKey, navigateTo.props);

    let secondPage;
    if (TwoColumnsModeManager.isEnabled()) {
      secondPage = TwoColumnsModeManager.showSecondPage(
        navigateTo.pageKey,
        navigateTo.props,
        selectedProfile,
        actions.navigate
      );
      console.log('Automatically displayed second page: ', secondPage);
    }
  });
}
