import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import config from 'data/config/config';
import './VimeoPlayer.scss';

const LOG_PREF = '[VimeoPlayer] ';

class VimeoPlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  hideSpinner = () => {
    this.setState({
      loading: false,
    });
  };

  render() {
    const { id } = this.props;
    const { loading } = this.state;

    if (!config.PLAYER_EMBED_VIMEOLIVE.FEATURE_ENABLED) {
      return null;
    }

    const url = `https://player.vimeo.com/video/${id || '162427937'}`;
    return (
      <div className="embed-container">
        {loading ? <div className="spinner"></div> : null}
        <iframe
          src={url}
          onLoad={this.hideSpinner}
          frameBorder="0"
          webkitallowfullscreen={'true'}
          mozallowfullscreen={'true'}
          allowFullScreen={true}
        ></iframe>
      </div>
    );
  }
}

VimeoPlayer.propTypes = {
  id: PropTypes.string,
  start_time: PropTypes.string,
  start_date: PropTypes.number,
  end_time: PropTypes.string,
  end_date: PropTypes.string,
  // common props
  labels: PropTypes.object,
  actions: PropTypes.object,
};

export default VimeoPlayer;
