module.exports = {
  name: "pdw2022",
  client: "SAFI",
  description: "Mobile app for PDW 2022",
  pluginId: "mobigeo-plugin-pdw",
  validLangs: ["fr", "en"],
  version: {
    mainVersion: "8.0.0",
    buildVersion: {
      iOS: "32",
      android: "31",
      web: "32"
    }
  },
  native: {
    author: "Mobile-Spot",
    backgroundColor: "0xffffff",
    iosBuildTarget: "11.0",
    androidFadeSplashScreenDuration: 750,
    androidTargetSdkVersion: 30,
    androidSigning: {
      keyAlias: "mobilespot-pdw",
      keyStorePassword: "D8rMFDnM5fhurW3t"
    },
    app: "app-react"
  },
  undeliveredFolders: ["source", "exports"],
  crypto: false,
  web: {}
};